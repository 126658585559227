import React, { useState, useEffect } from "react";
import { unified } from "unified";
import remarkParse from "remark-parse";
import remarkRehype from "remark-rehype";
import rehypeReact from "rehype-react";
import chevron_down from "../images/chevron-down.svg";

const FAQ: React.FC<{ question: string; answer: string; slug: string }> = ({
  question,
  answer,
  slug,
}) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const [faqUrl, setFaqUrl] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  function copyToClipboard() {
    navigator.clipboard.writeText(faqUrl);
    setCopySuccess(true);
    window.setTimeout(() => setCopySuccess(false), 2000);
  }

  function toggleFAQ() {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    const url = window.location.href.replace(window.location.hash, "");
    setFaqUrl(`${url}#${slug}`);

    if (window.location.toString() === faqUrl) {
      setIsOpen(true);
    }
  }, [slug, faqUrl]);

  const classes = isOpen ? `faq is-open` : `faq`;

  const vfile = unified()
    .use(remarkParse)
    .use(remarkRehype)
    .use(rehypeReact, { createElement: React.createElement })
    .processSync(answer);

  return (
    <div className={classes} id={slug}>
      <h2 className="faq-title">
        <a className="faq-link" href={`#${slug}`} onClick={copyToClipboard}>
          # {copySuccess && <div className="faq-notification">Copied!</div>}
        </a>
        <button className="faq-toggle" onClick={toggleFAQ}>
          <div className="faq-question">{question}</div>
          <div className="faq-chevron">
            <img alt="" src={chevron_down} />
          </div>
        </button>
      </h2>
      <div className="faq-answer">{vfile.result}</div>
    </div>
  );
};

export default FAQ;
