import React from "react";
import { graphql, PageProps } from "gatsby";

import { FaqsPage as seo } from "../../helpers/seo";

import FAQ from "../../components/FAQ";

import SectionLayout from "../../components/SectionLayout";

const FaqsPage: React.FC<PageProps<Queries.FaqsPageQuery>> = ({ data }) => (
  <SectionLayout id="about" {...seo}>
    {data.allFaqsYaml.nodes.map((section) => (
      <section key={section.id} className="mb-12">
        <h1 className="text-3xl mb-6">{section.header}</h1>
        <ul>
          {section.questions.map((elem, i) => (
            <FAQ key={i} {...elem} />
          ))}
        </ul>
      </section>
    ))}
  </SectionLayout>
);

export const query = graphql`
  query FaqsPage {
    allFaqsYaml {
      nodes {
        header
        id
        questions {
          answer
          slug
          question
        }
      }
    }
  }
`;

export default FaqsPage;
